import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import { 
    Box,
    Grid,
    makeStyles,
    AppBar,
    Button,
    Toolbar,
    TextField,
    Typography,
    Divider,
 } from "@material-ui/core";
import myTheme from '../SiteTheme';
import { ThemeProvider,withStyles } from '@material-ui/styles';
import { MdSend } from "react-icons/md";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  fullHeight:{
    height:'100vh',
    backgroundColor:myTheme.palette.secondary.dark,
  },
  appBarHeight:{
    backgroundColor:myTheme.palette.secondary.dark,
  },
  TwentiVH:{
    height:'20vh',
    backgroundColor:myTheme.palette.secondary.dark,
  },
  EighttyVH:{
    height:'80vh',
    backgroundColor:myTheme.palette.secondary.dark,
  },
  fullHeightPercentage:{
    width:'100%',
    backgroundColor:myTheme.palette.secondary.dark,
  },
  dividerColor:{
      backgroundColor:myTheme.palette.primary.main,
      marginBottom:theme.spacing(2),
  },
  FieldMarginBottom:{
    marginBottom:theme.spacing(2),
  },
  input: {
    color: myTheme.palette.primary.main,
  },
    buttonProgress: {
    color: myTheme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

}));


const CssTextField = withStyles({
  root: {
    '& label.MuiInputLabel-root': {
      color: myTheme.palette.primary.dark, 
    },
    '& .MuiInput-colorSecondary': {
      color: myTheme.palette.primary.main, 
    },
    '& label.Mui-focused': {
      color: myTheme.palette.primary.main, 
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: myTheme.palette.primary.dark, 
      },
      '&:hover fieldset': {
        borderColor: myTheme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: myTheme.palette.primary.dark, 
      },
    },
  },
})(TextField);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function Conract() {
    const classes = useStyles();
    useEffect(()=>{
        document.title="aunDev | Contact"
    });

    const [getName,setName] = useState('');
    const [getEmail,setEmail] = useState('');
    const [getSub,setSub] = useState('');
    const [getMsg,setMsg] = useState('');

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [msgCol, msgSCol] = useState("success");
    const [msgtext, msgSetText] = useState("Email Sent");


    const sendEmail = (e)=>{
        e.preventDefault();
        if (validFields()) {
            setLoading(true);
            const data = {
                name:getName,
                email:getEmail,
                sub:getSub,
                msg:getMsg,
            }
            axios.post("http://localhost:4000/api/sendEmail",data)
            axios.post("https://aundev-back.herokuapp.com/api/sendEmail",data)
            .then(()=>{
                setLoading(false);
                    setName('');
                    setEmail('');
                    setSub('');
                    setMsg('');
                    e.target.reset();
                    msgSCol("success");//error
                    msgSetText("Email Sent !");
                    setOpen(true);
            })
            .catch(()=>{
                setLoading(false);
                    msgSCol("error");//error
                    msgSetText("Email Sending Failed, Try again!");
                    setOpen(true);
            });
            
        } else {
            msgSCol("warning");//error
            msgSetText("Fill the form currectly ☝");
            setOpen(true);
        }
    }


    const validFields = ()=>{
        if (getName === '' || getEmail === '' || getSub === '' || getMsg === '') {
            return false;
        } else {
            return true;
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };
    


    return (
        <ThemeProvider theme={myTheme}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={msgCol}>
                {msgtext}
                </Alert>
            </Snackbar>
            {/* <Box bgcolor="secondary.main" className={classes.fullHeight}> */}
                <Grid container className={classes.TwentiVH}>
                    <Grid item xs={12}>
                        <AppBar className={classes.appBarHeight} position="static" elevation={0}>
                            <Toolbar>
                            <Button component={Link} to="/" color="primary" className={classes.noTextTransform}>Home</Button>
                            <Button component={Link} to="/know/more" color="primary" className={classes.noTextTransform}>Know more</Button>
                            <Button component={Link} to="/Projects" color="primary" className={classes.noTextTransform}>Projects</Button>
                            <Button component={Link} to="/Contact" color="primary" className={classes.noTextTransform}>Contact</Button>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                </Grid>
                <Grid container className={classes.EighttyVH} justify="center" >
                    <Grid item xs={10} sm={8} md={7} lg={6}>
                        <Typography variant="h3" color="primary">Contact</Typography>
                        <Divider className={classes.dividerColor}/>
                    <form onSubmit={sendEmail} className={classes.root} noValidate autoComplete="off">
                    
                        <CssTextField
                        type="text"
                            className={classes.FieldMarginBottom}
                            label="Your Name" 
                            variant="outlined"
                            id="custom-css-outlined-input"
                            color="primary"
                            fullWidth
                            InputProps={{
                                className: classes.input
                            }}
                            onChange={e =>setName(e.target.value )} 
                            name="sender_name" 
                        />
                        <CssTextField
                            type="email"
                            className={classes.FieldMarginBottom}
                            label="Your Email" 
                            variant="outlined"
                            id="custom-css-outlined-input"
                            color="primary"
                            fullWidth
                            InputProps={{
                                className: classes.input
                            }}
                            onChange={e =>setEmail(e.target.value )} 
                            name="sender_email" 
                        />
                        <CssTextField
                            className={classes.FieldMarginBottom}
                            label="Subject" 
                            variant="outlined"
                            id="custom-css-outlined-input"
                            color="primary"
                            fullWidth
                            InputProps={{
                                className: classes.input
                            }}
                            onChange={e =>setSub(e.target.value )} 
                            name="sender_sub" 
                        />
                        <CssTextField
                            className={classes.FieldMarginBottom}
                            label="Your Message" 
                            variant="outlined"
                            id="custom-css-outlined-input"
                            color="primary"
                            fullWidth
                            InputProps={{
                                className: classes.input
                            }}
                            onChange={e =>setMsg(e.target.value )} 
                            name="sender_msg" 
                            multiline rows={4} rowsMax={6}
                        />
                
                        <Grid container justify="center" >
                          {!loading?
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<MdSend>send</MdSend>}
                            fullWidth
                            type="submit"
                            disabled={loading}
                        >
                            Send
                        </Button>
                        :
                        <CircularProgress />
                        }
                        </Grid>
                        
                    </form>
                    </Grid>
                </Grid>
            {/* </Box> */}
        </ThemeProvider>
    )
}

export default Conract