import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Paper,Chip,IconButton} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import myTheme from '../SiteTheme';
import {Visibility,VisibilityOff} from '@material-ui/icons';
import {BiGitBranch} from 'react-icons/bi';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { banners } from '../asset/DB';
import ReactPlayer from 'react-player';
import MuiAlert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme)=>(
  {
  root: {
    maxWidth: 345,
    backgroundColor:myTheme.palette.secondary.main,
  },
  media: {
    height: 200,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft:theme.spacing(2),
  },
  rootPaper: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow:'none',
    backgroundColor:myTheme.palette.secondary.main,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor:myTheme.palette.primary.dark,
    color:myTheme.palette.secondary.dark,
  },
}
));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SingleProject(props) {
  const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [getMessage, setMessage] = React.useState('');

    const handleClick = (msg) => {
        setMessage(msg);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

  return (
    <ThemeProvider theme={myTheme}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                {getMessage}
                </Alert>
            </Snackbar>
    <Card className={classes.root}>
      <CardActionArea>
        {props.bannerType===banners.image?
          <CardMedia
            className={classes.media}
            image={props.banner}
            title="Contemplative Reptile"
            />
            :
          <ReactPlayer 
          url={props.banner}
          width='100%'
          height='200px'
          />
        }
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" color="primary">
            { props.title }
          </Typography>
          <Typography variant="body2" color="primary" component="p">
            {props.description}
          </Typography>
        </CardContent>
          {/* <Grid container direction="row"> */}
            <Paper component="ul" className={classes.rootPaper}>
              {props.technology.map((data) => {
                return (
                  <li key={data.id}>
                    <Chip
                      size="small"
                      icon={data.icon}
                      label={data.tech}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </Paper>
          {/* </Grid> */}
      </CardActionArea>
      <CardActions>
        {props.livedemo?
            <Button href={props.livedemo} target="blank" size="small" color="primary" startIcon={<Visibility />}>
            Live Demo
          </Button>
            :
          <Button onClick={()=>{handleClick("Demo Not Shareable Yet!")}} size="small" color="primary" startIcon={<VisibilityOff />}>
            Live Demo
          </Button>
        }
        
        {props.sourcecode?
          <Button href={props.sourcecode} target="blank" size="small" color="primary" startIcon={<BiGitBranch />}>
            Source Code
          </Button>
          :
          <Button onClick={()=>{handleClick("Source Code Not Shareable!")}} size="small" color="primary" startIcon={<BiGitBranch />}>
            Source Code
          </Button>
        }
      </CardActions>
    </Card>
    </ThemeProvider>
  );
}
