import { useEffect } from 'react'
import logo from '../img/Logo-png.png';
import myTheme from '../SiteTheme';
import { ThemeProvider } from '@material-ui/styles';
import { 
  Grid,
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
} from '@material-ui/core'

import { Link } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  upperlogo:{
    height:'60vh',
  },
  footerButtons:{
    height:'20vh',
  },
  appBarHeight:{
    height:'20vh',
  },
  footerButtonHeight:{
    height:'100%',
    borderRadius:'none',
    textTransform:'none'
  },
  footerButtonFirstHeight:{
    height:'100%',
    borderRadius:'none',
    backgroundColor: myTheme.palette.primary.dark,
    textTransform:'none'
  },
  noTextTransform:{
    textTransform:'none',
    fontSize:'1rem',
  }

}));


function Home() {
  const logoStyle={
    width: '100%'
  }

  const classes = useStyles();

  useEffect(() => {
    document.title="aunDev | Home";
  });

  return (
    <div className="App">
    <ThemeProvider theme={myTheme}>
      <Box bgcolor="primary.main">
        <Grid
          container
          >
          <Grid
            item xs={12}>
              <AppBar className={classes.appBarHeight} position="static" color="primary" elevation={0}>
                <Toolbar>
                  <Button component={Link} to="/" color="inherit" className={classes.noTextTransform}>Home</Button>
                  <Button component={Link} to="/know/more" color="inherit" className={classes.noTextTransform}>Know more</Button>
                  <Button component={Link} to="/Projects" color="inherit" className={classes.noTextTransform}>Projects</Button>
                  <Button component={Link} to="/Contact" color="inherit" className={classes.noTextTransform}>Contact</Button>
                </Toolbar>
              </AppBar>
          </Grid>
        </Grid>
        <Grid container className={classes.upperlogo}>
          <Grid
            container
            justify="center"
            >
              <Grid item xs={3}>
              <img style={logoStyle} src={logo} alt="logo"/>
              </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            >
              <Grid item xs={8}>
              <Typography align="center" variant="h6">Hello, I'm Abul Hasan Aunshon. I'm a full time Web Developer based in Bangladesh. My job is to simplefy the web for you. I like to work with JavaScript, PHP, Laravel, React.js, Vue.js and I am always open to learn new things.</Typography>
              </Grid>
          </Grid>
        </Grid>
      </Box>
        <Grid container className={classes.footerButtons}>
              <Grid item xs={12} md={6}>
              <Button component={Link} to="/know/more" className={classes.footerButtonFirstHeight} variant="contained" fullWidth><Typography variant="h5">Know more</Typography></Button>
              </Grid>
              <Grid item xs={12} md={6}>
              <Button component={Link} to="/Projects" className={classes.footerButtonHeight} variant="contained" color="secondary" fullWidth><Typography variant="h5">Projects</Typography></Button>
              </Grid>
        </Grid>
    </ThemeProvider>
    </div>
  );
}

export default Home;