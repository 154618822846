import React,{useEffect,useState} from 'react'
import SingleProject from './SingleProject';
import { Divider, Grid,Typography } from '@material-ui/core';
import { ThemeProvider,withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import myTheme from '../SiteTheme';

import { 
    makeStyles,
    AppBar,
    Toolbar,
    Button,
 } from "@material-ui/core";
 import {Link} from 'react-router-dom';

 import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SiLaravel, SiPhp, SiJava, SiReact, SiVueDotJs, SiRedux, SiMysql, SiFirebase } from "react-icons/si";
import { DiNodejs,DiAndroid } from "react-icons/di";
import { BsCheckAll } from "react-icons/bs";
import { projectList,allTech } from "../asset/DB";

const useStyles = makeStyles((theme) => ({
  fullHeight:{
    height:'100vh',
  },
  mainBack:{
    // backgroundColor:'white',
    backgroundColor:myTheme.palette.secondary.dark,
  },
  singleProjectGrid:{
      padding:theme.spacing(2),
  },
  dividerColor:{
      backgroundColor:myTheme.palette.primary.main,
      marginBottom:theme.spacing(2),
  },
  FieldMarginBottom:{
    marginBottom:theme.spacing(2),
  },
  appBar:{
      backgroundColor:myTheme.palette.secondary.dark,
      color:myTheme.palette.primary.main,
  },
  techBar:{
      backgroundColor:myTheme.palette.secondary.main,
      color:myTheme.palette.primary.main,
      borderRadius:theme.shape.borderRadius,
  },
    unCheckedColor:{
      color:myTheme.palette.primary.main,
  },
  checkedColor:{
      color: green[600],
  },

}));
const GreenCheckbox = withStyles({
  root: {
    color: myTheme.palette.primary.main,
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
function Projects() {
    const classes = useStyles();

    const [state, setState] = useState({
        allCheck : true,
        reactCheck : false,
        reduxCheck : false,
        vueCheck : false,
        laravelCheck : false,
        phpCheck : false,
        nodejsCheck : false,
        mysqlCheck : false,
        firebaseCheck : false,
        androidCheck : false,
        javaCheck : false,
    });

    const [getProjectList,setProjectList] = useState(projectList);
    const [getSelectedTech,setSelectedTech] = useState([]);

    const allCheckhandleChange = (event) => {
        // if (event.target.checked) {
            setState({ ...state, 
                [event.target.name]: true ,
                ["reactCheck"]: false ,
                ["reduxCheck"]: false ,
                ["vueCheck"]: false ,
                ["laravelCheck"]: false ,
                ["phpCheck"]: false ,
                ["nodejsCheck"]: false ,
                ["mysqlCheck"]: false ,
                ["firebaseCheck"]: false ,
                ["androidCheck"]: false ,
                ["javaCheck"]: false ,
            });
            setProjectList(projectList);
            setSelectedTech([]);
        // }else{
        //     setState({ ...state, [event.target.name]: event.target.checked });
        // }
    };

    const updateState = (temp)=>{
        console.log(temp);
        if (temp.length<1) {
            setProjectList(projectList);
        } else {   
            const beforeFilter = projectList;
            const afterFilter = beforeFilter.filter((singleProjectData)=>{
                const allTechTofilter = singleProjectData.tech;
                const filtered = allTechTofilter.reduce((a, o) => (a.push(o.tech), a), [])  
                return filtered.some(item => temp.includes(item)) 
            });
            // console.log(afterFilter);
            setProjectList(afterFilter);
        }
    }

    const checkMyTechAddition = (thisTech)=>{
        let temp = [...getSelectedTech];
        if (state.allCheck === true) {
            setSelectedTech([thisTech]);
            temp = [thisTech];
        }else{
            let res = getSelectedTech.findIndex(data=>data===thisTech);
            if (res === -1) {
                temp.push(thisTech);
                setSelectedTech(temp);
            } 
        }
        updateState(temp);
    }
    const checkMyTechRemove = (thisTech)=>{
        let indexNo = getSelectedTech.findIndex(data=>data===thisTech);
        let temp = [...getSelectedTech];
        if (indexNo !== -1) {
            temp.splice(indexNo, 1);
            setSelectedTech(temp);
            if (temp.length<1) {
                setState(state.allCheck=true);
            }
        }
        updateState(temp);
    }

    const reactCheckhandleChange = (event) => {
        if (event.target.checked) {
            checkMyTechAddition(allTech.reactjs);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.reactjs);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const reduxCheckhandleChange = (event) => {
            if (event.target.checked) {
            checkMyTechAddition(allTech.redux);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.redux);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const vueCheckhandleChange = (event) => {
            if (event.target.checked) {
            checkMyTechAddition(allTech.vuejs);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.vuejs);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const laravelCheckhandleChange = (event) => {
                if (event.target.checked) {
            checkMyTechAddition(allTech.laravel);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.laravel);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const phpCheckhandleChange = (event) => {
            if (event.target.checked) {
            checkMyTechAddition(allTech.php);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.php);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const nodejsCheckhandleChange = (event) => {
            if (event.target.checked) {
            checkMyTechAddition(allTech.nodejs);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.nodejs);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const mysqlCheckhandleChange = (event) => {
            if (event.target.checked) {
            checkMyTechAddition(allTech.mysql);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.mysql);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const firebaseCheckhandleChange = (event) => {
            if (event.target.checked) {
            checkMyTechAddition(allTech.firebase);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.firebase);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const androidCheckhandleChange = (event) => {
            if (event.target.checked) {
            checkMyTechAddition(allTech.android);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.android);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };
    const javaCheckhandleChange = (event) => {

        if (event.target.checked) {
            checkMyTechAddition(allTech.java);
            setState({ ...state, 
                [event.target.name]: event.target.checked ,
                ["allCheck"]: false ,
            });
        }else{
            checkMyTechRemove(allTech.java);
            setState({ ...state, [event.target.name]: event.target.checked });
        }
    };

    useEffect(()=>{
        document.title="aunDev | Projects"
        document.body.style.backgroundColor = myTheme.palette.secondary.dark;
        
        // if (getSelectedTech.length<1) {
        //     setProjectList(projectList);
        // } else {   
        //     const beforeFilter = projectList;
        //     // console.log(getSelectedTech.findIndex(d=>beforeFilter[0].tech[0].tech===d));
        //     const afterFilter = beforeFilter.filter(checkNow);
        //     setProjectList(afterFilter);
        // }
    });

    return (
        <ThemeProvider theme={myTheme}>
                <Grid container justify="center">
                    <Grid item xs={12} md={10}>
                        <AppBar className={classes.appBar} position="static" elevation={0}>
                            <Toolbar>
                            <Button component={Link} to="/" color="inherit" className={classes.noTextTransform}>Home</Button>
                            <Button component={Link} to="/know/more" color="inherit" className={classes.noTextTransform}>Know more</Button>
                            <Button component={Link} to="/Projects" color="inherit" className={classes.noTextTransform}>Projects</Button>
                            <Button component={Link} to="/Contact" color="inherit" className={classes.noTextTransform}>Contact</Button>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                {/* <Typography className={classes.appBar} gutterBottom variant="h4">vue.js</Typography> */}
                                <Divider className={classes.dividerColor}/>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <AppBar className={classes.techBar} position="static" elevation={0}>
                                        <Toolbar>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.allCheck} icon={<BsCheckAll />} onChange={allCheckhandleChange} checkedIcon={<BsCheckAll />} name="allCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.allCheck?classes.checkedColor:classes.unCheckedColor}>All</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.reactCheck} icon={<SiReact />} onChange={reactCheckhandleChange} checkedIcon={<SiReact />} name="reactCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.reactCheck?classes.checkedColor:classes.unCheckedColor}>React.js</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.reduxCheck} icon={<SiRedux />} onChange={reduxCheckhandleChange} checkedIcon={<SiRedux />} name="reduxCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.reduxCheck?classes.checkedColor:classes.unCheckedColor}>redux</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.vueCheck} icon={<SiVueDotJs />} onChange={vueCheckhandleChange} checkedIcon={<SiVueDotJs />} name="vueCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.vueCheck?classes.checkedColor:classes.unCheckedColor}>Vue.js</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.laravelCheck} icon={<SiLaravel />} onChange={laravelCheckhandleChange} checkedIcon={<SiLaravel />} name="laravelCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.laravelCheck?classes.checkedColor:classes.unCheckedColor}>Jaravel</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.phpCheck} icon={<SiPhp />} onChange={phpCheckhandleChange} checkedIcon={<SiPhp />} name="phpCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.phpCheck?classes.checkedColor:classes.unCheckedColor}>php</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.nodejsCheck} icon={<DiNodejs />} onChange={nodejsCheckhandleChange} checkedIcon={<DiNodejs />} name="nodejsCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.nodejsCheck?classes.checkedColor:classes.unCheckedColor}>Node.js</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.mysqlCheck} icon={<SiMysql />} onChange={mysqlCheckhandleChange} checkedIcon={<SiMysql />} name="mysqlCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.mysqlCheck?classes.checkedColor:classes.unCheckedColor}>mysql</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.firebaseCheck} icon={<SiFirebase />} onChange={firebaseCheckhandleChange} checkedIcon={<SiFirebase />} name="firebaseCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.firebaseCheck?classes.checkedColor:classes.unCheckedColor}>firebase</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.androidCheck} icon={<DiAndroid />} onChange={androidCheckhandleChange} checkedIcon={<DiAndroid />} name="androidCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.androidCheck?classes.checkedColor:classes.unCheckedColor}>Android</Typography>}
                                                />
                                                <FormControlLabel
                                                    control={<GreenCheckbox checked={state.javaCheck} icon={<SiJava />} onChange={javaCheckhandleChange} checkedIcon={<SiJava />} name="javaCheck" color="primary" />}
                                                    label={<Typography variant="body1" className={state.javaCheck?classes.checkedColor:classes.unCheckedColor}>Java</Typography>}
                                                />
                                            </FormGroup>
                                        </Toolbar>
                                    </AppBar>
                            {/* <Grid item xs={12}>
                                <Divider className={classes.dividerColor}/>
                            </Grid>  */}
                                </Grid>
                            </Grid>
                            
                            {
                                getProjectList.map(data=>{
                                    return(
                                        <Grid key={data.id} item xs={12} sm={6} md={4} className={classes.singleProjectGrid}>
                                            <SingleProject 
                                                title={data.projectName}
                                                description={data.description}
                                                technology={data.tech}
                                                livedemo={data.livedemo}
                                                sourcecode={data.sourcecode}
                                                bannerType={data.bannerType}
                                                banner={data.banner}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
        </ThemeProvider>
    )
}

export default Projects
