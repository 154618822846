import { useEffect,useState } from 'react'
import aun_logo from '../img/aun-Logo-png.png';
import Aunshon_Developer_pdf from '../asset/files/Aunshon_Developer.pdf';
import logo from '../img/Logo-png.png';
import myTheme from '../SiteTheme';
import { ThemeProvider } from '@material-ui/styles';
import { 
  Grid,
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Hidden,
  IconButton,
  Divider,
} from '@material-ui/core'

import { Link } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';
import { ImLinkedin } from 'react-icons/im';
import { AiFillTwitterCircle, AiFillGithub } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import Paper from '@material-ui/core/Paper';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { SiLaravel, SiPhp, SiJavascript, SiVueDotJs,SiReact} from "react-icons/si";
import { TiArrowForward} from "react-icons/ti";
import { MdWork} from "react-icons/md";



const useStyles = makeStyles((theme) => ({
  fullHeight:{
    height:'100vh',
  },
  fullHeightPercentage:{
    width:'100%',
  },
  upperlogo:{
    height:'60vh',
    width:'100%',
  },
  welcome:{
    paddingBottom:theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  about:{
    paddingBottom:theme.spacing(8),
    paddingLeft: theme.spacing(4),
  },
  paddingLeftApply:{
    paddingLeft: theme.spacing(4),
  },
  paddingLeftToSocial:{
    paddingLeft: theme.spacing(2),
  },
  mylogo:{
    width: '100%',
    transition:'all 1s ease-out',
  },
  thisLink:{
      color:myTheme.palette.primary.dark
  },
    paper: {
    padding: '6px 16px',
    backgroundColor: myTheme.palette.secondary.dark,
  },
    paper1: {
    padding: '6px 16px',
    backgroundColor: myTheme.palette.primary.dark,
  },
  secondaryTail: {
    backgroundColor: myTheme.palette.secondary.dark,
  },
  lowTypo:{
      paddingTop:'100px',
  },
    dividerColor:{
      backgroundColor:myTheme.palette.primary.main,
      marginBottom:theme.spacing(2),
  },

}));



function About() {

    const classes = useStyles();
    useEffect(()=>{
        document.title="aunDev | About"
    });
    const [getLogoState,setLogoState] = useState(true);
    const [a,b] = useState('1');
    const [c,d] = useState('0');
    function logoChanger(data) {
      if (data === true) {
        
        d('0');
        b('1');
        setTimeout(() => {
          setLogoState(data);
        }, 500);
      } else {
        
        b('0');
        d('1');
        setTimeout(() => {
          setLogoState(data);
        }, 500);
      }
    }

    return (
        <ThemeProvider theme={myTheme}>
            <Box bgcolor="primary.main">
                <Grid
                    container
                    >
                    <Hidden smDown>
                    <Grid item xs={6} className={classes.fullHeight}>
                        <Grid container className={classes.upperlogo}>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                className={classes.fullHeight}
                                >

                                {getLogoState?
                                    <Grid item xs={6}>
                                    <img onMouseEnter={()=>logoChanger(false)} style={{ opacity:a }} className={classes.mylogo} src={aun_logo} alt="logo"/>
                                    </Grid>
                                :
                                    <Grid item xs={6}>
                                    <img onMouseLeave={()=>logoChanger(true)} style={{ opacity:c }} className={classes.mylogo} src={logo} alt="logo"/>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    </Hidden>


                    <Grid item xs={12} md={6} className={classes.fullHeight}>
                        <Box bgcolor="secondary.main" color="primary.main" className={classes.fullHeight}>
                            <Grid container justify="center" className={classes.fullHeight}>
                            <Grid item xs={12} md={10}>
                                <AppBar position="static" color="secondary" elevation={0}>
                                    <Toolbar>
                                    <Grid container justify="space-between">
                                        <Button component={Link} to="/" color="inherit" className={classes.thisLink}>Home</Button>
                                        <Button component={Link} to="/know/more" color="inherit" >Know more</Button>
                                        <Button component={Link} to="/Projects" color="inherit" className={classes.thisLink}>Projects</Button>
                                        <Button component={Link} to="/Contact" color="inherit" className={classes.thisLink}>Contact</Button>
                                    </Grid>
                                    </Toolbar>
                                </AppBar>
                                <Hidden mdUp>
                                    <Divider className={classes.dividerColor}/>
                                </Hidden>
                            </Grid>
                            <Grid item xs={10}>
                                <Hidden smDown>
                                <Typography variant="h4" color="primary" className={classes.welcome}>Welcome</Typography>
                                </Hidden>
                                <Hidden mdUp>
                                <Typography variant="h6" color="primary" className={classes.welcome}>Welcome</Typography>
                                </Hidden>
                                <Typography variant="body2" color="primary" className={classes.about}>
                                    I developed several web projects for
                                    company and individuals, i mostly use Laravel and JavaScript/react/vue also so that i
                                    can finish projects very fast, and I always use GIT to track my progress and files,
                                    as well to collaborate with other developers.
                                    <br/>
                                    <br/>
                                    Among four person on my family i'm the youngest. I have one elder brother. And my father
                                    he is a retired Govt. employee but now working as a service holder in Home Tex. And my Mother is a 
                                    house wife.
                                    <br/><br/>
                                    From childhood i was attracted on technology and was that kind of child
                                    who is always doing something with wire, motors and led lights. But when started learning 
                                    coding my life and thinking changed. I always try to learn by myself and apply them in life.
                                    I was a lab prefect in university to help the new students to learn programming and projects. 
                                    I always say my-self that, i am not an expert but I have the ability to run behind the solution and catch it.
                                </Typography>
                                <Typography variant="body2" color="primary" className={classes.paddingLeftApply}> aunshonhasan@gmail.com / aunshon@hotmail.com</Typography>
                                <Typography variant="body2" color="primary" className={classes.welcome}>+880 1686222943</Typography>
                                
                                {/* <Grid item xs={10}> */}
                                        <Grid container className={classes.paddingLeftToSocial}>
                                            <IconButton href="https://www.facebook.com/aunshon/" target="blank" color="inherit" ><FaFacebook/></IconButton>
                                            {/* <IconButton href="https://github.com/aunshon" target="blank" color="inherit" ><AiFillInstagram/></IconButton> */}
                                            <IconButton href="https://twitter.com/aunshon" target="blank" color="inherit" ><AiFillTwitterCircle/></IconButton>
                                            <IconButton href="https://www.linkedin.com/in/aunshon/" target="blank" color="inherit" ><ImLinkedin/></IconButton>
                                            <IconButton href="https://www.github.com/aunshon" target="blank" color="inherit" ><AiFillGithub/></IconButton>
                                            <Button href={Aunshon_Developer_pdf} startIcon={<FiDownload/>} color="primary" download size="small" variant="outlined">resume</Button>
                                        </Grid>
                                {/* </Grid> */}
                            </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.fullHeight}>
                        <Box bgcolor="secondary.main" color="primary.main" className={classes.fullHeight}>
                            <Grid container justify="center" className={classes.fullHeight}>
                                
                                <Typography variant="h3" color="primary" className={classes.lowTypo}>Skill</Typography>
                      
                            <Grid item xs={12}>
                                                            

                                                                    <Timeline align="alternate">

                                                                    <TimelineItem>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="primary">
                                                                            <SiLaravel />
                                                                        </TimelineDot>
                                                                        <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper}>
                                                                            <Typography variant="h6" component="h1" color="primary">
                                                                            Laravel
                                                                            </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>

                                                                    <TimelineItem>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="primary">
                                                                            <SiReact />
                                                                        </TimelineDot>
                                                                        <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper}>
                                                                            <Typography variant="h6" component="h1" color="primary">
                                                                            React.js
                                                                            </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>

                                                                    <TimelineItem>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="primary">
                                                                            <SiVueDotJs />
                                                                        </TimelineDot>
                                                                        <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper}>
                                                                            <Typography variant="h6" component="h1" color="primary">
                                                                            Vue.js
                                                                            </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>

                                                                    <TimelineItem>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="primary">
                                                                            <SiJavascript />
                                                                        </TimelineDot>
                                                                        <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper}>
                                                                            <Typography variant="h6" component="h1" color="primary">
                                                                            JavaScript
                                                                            </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>

                                                                    <TimelineItem>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="primary">
                                                                            <SiPhp />
                                                                        </TimelineDot>
                                                                        <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper}>
                                                                            <Typography variant="h6" component="h1" color="primary">
                                                                            PHP
                                                                            </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>

                                                                    <TimelineItem>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="primary">
                                                                            <TiArrowForward />
                                                                        </TimelineDot>
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper}>
                                                                            <Typography variant="h6" component="h1" color="primary">
                                                                            And More
                                                                            </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                    </Timeline>



                            </Grid>
                            </Grid>
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={6} className={classes.fullHeight}>
                        <Grid container justify="center" className={classes.fullHeight}>
                                <Typography variant="h3" color="secondary" className={classes.lowTypo}>Experience</Typography>
                            <Grid item xs={12}>
                                                            

                                                                    <Timeline align="alternate">
                                                                    <TimelineItem>
                                                                        <TimelineOppositeContent>
                                                                        <Typography variant="body2" color="secondary">
                                                                            August 2020 - Present - Upautomation ltd.
                                                                        </Typography>
                                                                        </TimelineOppositeContent>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="secondary">
                                                                            <MdWork />
                                                                        </TimelineDot>
                                                                        <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper1}>
                                                                            <Typography variant="h6" component="h1" color="secondary">
                                                                            Web Developer
                                                                            </Typography>
                                                                            <Typography color="secondary">Laravel & PHP | Vue & Vuex | Rest API | GitHub | CSS</Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                    <TimelineItem>
                                                                        <TimelineOppositeContent>
                                                                        <Typography variant="body2" color="secondary">
                                                                            August 2019 - March 2020 - Xubisoft Ltd. Dhaka
                                                                        </Typography>
                                                                        </TimelineOppositeContent>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="secondary">
                                                                            <MdWork />
                                                                        </TimelineDot>
                                                                        <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper1}>
                                                                            <Typography variant="h6" component="h1" color="secondary">
                                                                            Web Developer
                                                                            </Typography>
                                                                            <Typography color="secondary">Laravel | JavaScript | PHP | Rest API | Git | GitHub </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                    <TimelineItem>
                                                                        <TimelineOppositeContent>
                                                                        <Typography variant="body2" color="secondary">
                                                                            May 2017 - April 2018 - DIU CPC Bangladesh
                                                                        </Typography>
                                                                        </TimelineOppositeContent>
                                                                        <TimelineSeparator>
                                                                        <TimelineDot color="secondary">
                                                                            <MdWork />
                                                                        </TimelineDot>
                                                                        </TimelineSeparator>
                                                                        <TimelineContent>
                                                                        <Paper elevation={3} className={classes.paper1}>
                                                                            <Typography variant="h6" component="h1" color="secondary">
                                                                            Lab prefect - Programing & project Guid
                                                                            </Typography>
                                                                            <Typography color="secondary">I was assigned to first year students sections to monitor and guid in Programing in Java and help to their projects </Typography>
                                                                        </Paper>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                    </Timeline>



                            </Grid>
                            </Grid>
                    </Grid>

                </Grid>
            </Box>
        </ThemeProvider>
    )
}

export default About
