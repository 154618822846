import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#F7F7F7',
            dark: '#E0E0E0',
        },
        secondary: {
            // This is green.A700 as hex.
            light: '#9e9e9e',
            main: '#2B2B2B',
            dark: '#1b1b1b',
        },
    },
});
export default theme;