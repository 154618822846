import Home from './Components/Home'
import About from './Components/About'
import Projects from './Components/Projects'
import Contact from './Components/Contact'
import NoRouteFound from './Components/NoRouteFound'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route exact={true} path="/know/more" component={About} />
          <Route exact={true} path="/Projects" component={Projects} />
          <Route exact={true} path="/Contact" component={Contact} />
          <Route component={NoRouteFound} />
        </Switch>
      </Router>
    </>
  )
}

export default App
