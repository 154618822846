import React from 'react'

function NoRouteFound() {
    return (
        <div>
            <h1>404!</h1>
        </div>
    )
}

export default NoRouteFound
