import healthCare from './img/healthCare.jpg'
import bongobondhu from './img/bongobondhu.png'
import up from './img/up.png'
import xubi from './img/xubi.png'
import reactTodo from './img/reactTodo.png'
import aundev from './img/aundev.png'
import cannadaClassical from './img/cannadaClassical.png'
import innova from './img/innova.png'
import marrage from './img/marrage.png'
import inventory from './img/inventory.png'
import Ketherinn from './img/kathrin.png'
import diet from './img/diet.jpg'
import mealandroid from './img/mealandroid.jpg'
import meald from './img/meald.jpg'
import store from './img/store.jpg'
import screenRecorder from './img/aunScreenRecorder.png'
import cowrie from './img/cowrie.jpg'


import { SiLaravel, SiEclipseide, SiAndroidstudio, SiComposer, SiArduino, SiGooglestreetview, SiMaterialUi, SiBootstrap, SiHtml5, SiJquery, SiCss3, SiPhp, SiJavascript, SiJava, SiReact, SiVueDotJs, SiRedux, SiReactrouter, SiMysql, SiFirebase } from "react-icons/si";
import { FaVuejs, FaSass, FaRegSmile, FaFileExcel } from "react-icons/fa";
import { BsTable } from "react-icons/bs";
import { DiNodejs, DiNetbeans, DiMaterializecss, DiAndroid, DiSqllite } from "react-icons/di";
import { VscColorMode } from "react-icons/vsc";
import { TiArrowLoop } from "react-icons/ti";

export const banners = { image: 'image', video: 'video' };
export const allTech = {
    laravel: 'laravel',
    php: 'php',
    javascript: 'JavaScript',
    reactjs: 'react.js',
    redux: 'redux',
    reactrouter: 'react router',
    vuejs: 'vue.js',
    vuex: 'vuex',
    vuerouter: 'vue router',
    java: 'java',
    mysql: 'mysql',
    firebase: 'firebase',
    nodejs: 'node.js',
    materialui: 'material ui',
    materializecss: 'materialize css',
    bootstrap: 'bootstrap',
    jquery: 'jquery',
    css: 'css',
    scss: 'scss',
    datatable: 'datatable',
    DarkMode: 'DarkMode',
    reacticons: 'react-icons',
    android: 'android',
    googlemap: 'Google Map',
    SiArduino: 'Arduino',
    html: 'HTML',
    ajax: 'AJAX',
    composer: 'Composer',
    xml: 'XML',
    sqlite: 'SQLite',
    androidstudio: 'Android Studio',
    netbeans: 'Netbeans',
    eclipse: 'Eclipse ide',

};
export const allTechIcon = {
    laravel: < SiLaravel / > ,
    php: < SiPhp / > ,
    javascript: < SiJavascript / > ,
    reactjs: < SiReact / > ,
    redux: < SiRedux / > ,
    reactrouter: < SiReactrouter / > ,
    vuejs: < SiVueDotJs / > ,
    vuex: < FaVuejs / > ,
    vuerouter: < SiVueDotJs / > ,
    java: < SiJava / > ,
    mysql: < SiMysql / > ,
    firebase: < SiFirebase / > ,
    node: < DiNodejs / > ,
    materialui: < SiMaterialUi / > ,
    materializecss: < DiMaterializecss / > ,
    bootstrap: < SiBootstrap / > ,
    html: < SiHtml5 / > ,
    jquery: < SiJquery / > ,
    css: < SiCss3 / > ,
    scss: < FaSass / > ,
    DarkMode: < VscColorMode / > ,
    reacticons: < FaRegSmile / > ,
    datatable: < BsTable / > ,
    android: < DiAndroid / > ,
    googlemap: < SiGooglestreetview / > ,
    arduino: < SiArduino / > ,
    ajax: < TiArrowLoop / > ,
    composer: < SiComposer / > ,
    xml: < FaFileExcel / > ,
    androidstudio: < SiAndroidstudio / > ,
    netbeans: < DiNetbeans / > ,
    eclipse: < SiEclipseide / > ,
    sqlite: < DiSqllite / > ,

};

export const projectList = [{
        id: 23,
        projectName: "Aun Screen Recorder",
        description: "This is a online Free Screen Recorder. It has some settings for better experience like format, frame rate, video bit rate, audio bit rate, and count down before start.",
        bannerType: banners.image,
        banner: screenRecorder,
        tech: [
            { id: 1, tech: allTech.reactjs, icon: allTechIcon.reactjs },
            { id: 2, tech: allTech.redux, icon: allTechIcon.redux },
            { id: 3, tech: allTech.materialui, icon: allTechIcon.materialui },
            { id: 4, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 5, tech: allTech.reacticons, icon: allTechIcon.reacticons },
            { id: 7, tech: allTech.css, icon: allTechIcon.css },
        ],
        livedemo: "https://aun-recorder.netlify.app/",
        sourcecode: 'https://github.com/Aunshon/Aun_Screen_Recorder.git',

    },
    {
        id: 24,
        projectName: "Cowrie group uk",
        description: "This is a online market place for cowriegroupuk compacy. Basically it is a Ecommerce For thet company. This project is developed in React.js, larave and mysql database.",
        bannerType: banners.image,
        banner: cowrie,
        tech: [
            { id: 1, tech: allTech.reactjs, icon: allTechIcon.reactjs },
            { id: 2, tech: allTech.redux, icon: allTechIcon.redux },
            { id: 3, tech: allTech.materialui, icon: allTechIcon.materialui },
            { id: 4, tech: allTech.laravel, icon: allTechIcon.laravel },
            { id: 5, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 7, tech: allTech.css, icon: allTechIcon.css },
        ],
        livedemo: "http://www.cowriegroupuk.com/",
        sourcecode: 'https://github.com/Aunshon/CowrieUK.git',

    },
    {
        id: 1,
        projectName: "Mediboy",
        description: "This is a online market place / store for medicine. Where people can buy any medicine from any pharmacy. And even they can get home delivery service from us.",
        bannerType: banners.video,
        banner: "https://youtu.be/6BO_Pp6yVnA",
        tech: [
            { id: 1, tech: allTech.laravel, icon: allTechIcon.laravel },
            { id: 3, tech: allTech.vuejs, icon: allTechIcon.vuejs },
            { id: 4, tech: allTech.vuex, icon: allTechIcon.vuex },
            { id: 5, tech: allTech.vuerouter, icon: allTechIcon.vuerouter },
            { id: 6, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 7, tech: allTech.materializecss, icon: allTechIcon.materializecss },
        ],
        livedemo: "https://youtu.be/6BO_Pp6yVnA",
        sourcecode: 'https://github.com/Aunshon/Mediboy',

    },
    {
        id: 3,
        projectName: "Up Automation",
        description: "This project for digital union software product by upautomation ltd. Here Id card can be generated like UP employee and stuff. Tax management and calculation and tax payment.",
        bannerType: banners.image,
        banner: up,
        tech: [
            { id: 1, tech: allTech.laravel, icon: allTechIcon.laravel },
            { id: 3, tech: allTech.jquery, icon: allTechIcon.jquery },
            { id: 4, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 5, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 6, tech: allTech.css, icon: allTechIcon.css },
            { id: 7, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
            { id: 8, tech: allTech.datatable, icon: allTechIcon.datatable },
        ],
        livedemo: null,
        sourcecode: null,

    },
    {
        id: 4,
        projectName: "XubiSoft.ltd",
        description: "Xubisoft's own site, you will find it's products, services. Publish registration form so that students can enrole to courses and enrolment can be controlled by admin.",
        bannerType: banners.image,
        banner: xubi,
        tech: [
            { id: 1, tech: allTech.laravel, icon: allTechIcon.laravel },
            { id: 3, tech: allTech.jquery, icon: allTechIcon.jquery },
            { id: 4, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 5, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 6, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
            { id: 7, tech: allTech.datatable, icon: allTechIcon.datatable },
        ],
        livedemo: "https://xubisoft.com/",
        sourcecode: null,

    },
    {
        id: 6,
        projectName: "aunDev",
        description: "This is my porifolio built on React.js and google Material Ui, here you will find my projects, about my-self, and more informatino about me. Also contact with me.",
        bannerType: banners.image,
        banner: aundev,
        tech: [
            { id: 1, tech: allTech.reactjs, icon: allTechIcon.reactjs },
            { id: 2, tech: allTech.reactrouter, icon: allTechIcon.reactrouter },
            { id: 3, tech: allTech.materialui, icon: allTechIcon.materialui },
            { id: 4, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 5, tech: allTech.reacticons, icon: allTechIcon.reacticons },
            { id: 7, tech: allTech.css, icon: allTechIcon.css },
        ],
        livedemo: window.location.origin,
        sourcecode: "https://github.com/Aunshon/aunDev.git",

    },
    {
        id: 7,
        projectName: "React.js Todo",
        description: "React.js Application that gets new todo, single select and multiple select and add to complete list, todo update and delete, dubble click to make text editable, clear completed todo list",
        bannerType: banners.image,
        banner: reactTodo,
        tech: [
            { id: 1, tech: allTech.reactjs, icon: allTechIcon.reactjs },
            { id: 2, tech: allTech.materializecss, icon: allTechIcon.materializecss },
            { id: 3, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 4, tech: allTech.html, icon: allTechIcon.html },
            { id: 5, tech: allTech.css, icon: allTechIcon.css },
            { id: 6, tech: allTech.DarkMode, icon: allTechIcon.DarkMode },
        ],
        livedemo: "https://aun-react-todo.netlify.app/",
        sourcecode: null,
    },
    {
        id: 8,
        projectName: "Vue.js Todo",
        description: "Vue.js Application that gets new todo, single select and multiple select and add to complete list, todo update and delete, dubble click to make text editable, clear completed todo list",
        bannerType: banners.image,
        banner: reactTodo,
        tech: [
            { id: 1, tech: allTech.vuejs, icon: allTechIcon.vuejs },
            { id: 2, tech: allTech.materializecss, icon: allTechIcon.materializecss },
            { id: 3, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 4, tech: allTech.html, icon: allTechIcon.html },
            { id: 5, tech: allTech.css, icon: allTechIcon.css },
            { id: 6, tech: allTech.DarkMode, icon: allTechIcon.DarkMode },
        ],
        livedemo: "https://aun-vue-todo.netlify.app/",
        sourcecode: null,
    },
    {
        id: 9,
        projectName: "Secure Your Child - (Parent)",
        description: "Android tracking app parents can track childern & monitor location on google map, set Geo-fence when child enter & exit parents gets notification on phone. Add multiple children & monitor.",
        bannerType: banners.video,
        banner: "https://youtu.be/CvD0rXsRPEw",
        tech: [
            { id: 1, tech: allTech.android, icon: allTechIcon.android },
            { id: 5, tech: allTech.androidstudio, icon: allTechIcon.androidstudio },
            { id: 4, tech: allTech.googlemap, icon: allTechIcon.googlemap },
            { id: 2, tech: allTech.firebase, icon: allTechIcon.firebase },
            { id: 3, tech: allTech.xml, icon: allTechIcon.xml },
        ],
        livedemo: "https://youtu.be/CvD0rXsRPEw",
        sourcecode: "https://github.com/Aunshon/SecureYourChild_for_Parent.git",

    },
    {
        id: 10,
        projectName: "Secure Your Child - (Child)",
        description: "Android tracking app child can share their location to parents by secured Qr-Code scannig syatem. child can also see own location and Home location. Auth works by phone OTP.",
        bannerType: banners.video,
        banner: "https://youtu.be/CvD0rXsRPEw",
        tech: [
            { id: 1, tech: allTech.android, icon: allTechIcon.android },
            { id: 5, tech: allTech.androidstudio, icon: allTechIcon.androidstudio },
            { id: 4, tech: allTech.googlemap, icon: allTechIcon.googlemap },
            { id: 2, tech: allTech.firebase, icon: allTechIcon.firebase },
            { id: 3, tech: allTech.xml, icon: allTechIcon.xml },
        ],
        livedemo: "https://youtu.be/CvD0rXsRPEw",
        sourcecode: "https://github.com/Aunshon/SecureYourChild_For_Child.git",

    },
    {
        id: 11,
        projectName: "City Bus Admin",
        description: "Location-based bus station finding app especially for the admin app. It shows all bus stops, add new stops, add a bus to new stops, search them, and show them on the google map.",
        bannerType: banners.video,
        banner: "https://youtu.be/mJl0uv7jWdo",
        tech: [
            { id: 1, tech: allTech.android, icon: allTechIcon.android },
            { id: 5, tech: allTech.androidstudio, icon: allTechIcon.androidstudio },
            { id: 4, tech: allTech.googlemap, icon: allTechIcon.googlemap },
            { id: 2, tech: allTech.firebase, icon: allTechIcon.firebase },
            { id: 3, tech: allTech.xml, icon: allTechIcon.xml },
        ],
        livedemo: "https://youtu.be/mJl0uv7jWdo",
        sourcecode: "https://github.com/Aunshon/NearestStation_For_Admin.git",

    },
    {
        id: 12,
        projectName: "City Bus User",
        description: "Bus staiton finder for Users. This app especially for the User. This app task is to show all bus stops, search bus stops, show by place show by bus and show them on the google map.",
        bannerType: banners.video,
        banner: "https://youtu.be/xAoaC239t78",
        tech: [
            { id: 1, tech: allTech.android, icon: allTechIcon.android },
            { id: 5, tech: allTech.androidstudio, icon: allTechIcon.androidstudio },
            { id: 4, tech: allTech.googlemap, icon: allTechIcon.googlemap },
            { id: 2, tech: allTech.firebase, icon: allTechIcon.firebase },
            { id: 3, tech: allTech.xml, icon: allTechIcon.xml },
        ],
        livedemo: "https://youtu.be/xAoaC239t78",
        sourcecode: "https://github.com/Aunshon/NearestStationFromYou.git",

    },
    {
        id: 19,
        projectName: "Meal management",
        description: "This is a android app that helps to manage the expence and meal manage. It is especially made for bachelor. it task is to take input of expence, diposit, meal and it will meal rate and due of persons.",
        bannerType: banners.image,
        banner: mealandroid,
        tech: [
            { id: 1, tech: allTech.android, icon: allTechIcon.android },
            { id: 2, tech: allTech.androidstudio, icon: allTechIcon.androidstudio },
            { id: 4, tech: allTech.sqlite, icon: allTechIcon.sqlite },
            { id: 3, tech: allTech.xml, icon: allTechIcon.xml },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/Meal.git",

    },
    {
        id: 18,
        projectName: "Diet Tips",
        description: "This a an android app made for client. It's task is to show infotmation about health tips. there are different option. diet tip. workout tip. and add showing option but it is disabled now.",
        bannerType: banners.image,
        banner: diet,
        tech: [
            { id: 1, tech: allTech.android, icon: allTechIcon.android },
            { id: 4, tech: allTech.androidstudio, icon: allTechIcon.androidstudio },
            { id: 2, tech: allTech.firebase, icon: allTechIcon.firebase },
            { id: 3, tech: allTech.xml, icon: allTechIcon.xml },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/DietTips.git",

    },
    {
        id: 5,
        projectName: "Cannada Classical",
        description: "This is a Real-state complay website for one of my clients. Tiis site contains some basic functionality. All the content canbe controlled from backend by admin only.",
        bannerType: banners.image,
        banner: cannadaClassical,
        tech: [
            { id: 1, tech: allTech.laravel, icon: allTechIcon.laravel },
            { id: 3, tech: allTech.css, icon: allTechIcon.css },
            { id: 4, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 5, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 6, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/canada-classical.git",

    },
    {
        id: 13,
        projectName: "Innova CMS",
        description: "This is a complay website for one of my clients. Tiis site contains some basic functionality. All the content canbe controlled from backend by admin only.",
        bannerType: banners.image,
        banner: innova,
        tech: [
            { id: 1, tech: allTech.php, icon: allTechIcon.php },
            { id: 2, tech: allTech.html, icon: allTechIcon.html },
            { id: 3, tech: allTech.css, icon: allTechIcon.css },
            { id: 4, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 5, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/Php_Innova.git",

    },
    {
        id: 14,
        projectName: "Php Todo AJSX",
        description: "Php Application that gets new todo, single select and multiple select and add to complete list, todo update and delete, dubble click to make text editable, clear completed todo list",
        bannerType: banners.image,
        banner: reactTodo,
        tech: [
            { id: 1, tech: allTech.php, icon: allTechIcon.php },
            { id: 2, tech: allTech.materializecss, icon: allTechIcon.materializecss },
            { id: 3, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 4, tech: allTech.composer, icon: allTechIcon.composer },
            { id: 5, tech: allTech.ajax, icon: allTechIcon.ajax },
            { id: 6, tech: allTech.mysql, icon: allTechIcon.mysql },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/Todo-App.git",
    },
    {
        id: 15,
        projectName: "PHP Ketherinn's project",
        description: "Php Application that gets new todo, single select and multiple select and add to complete list, todo update and delete, dubble click to make text editable, clear completed todo list",
        bannerType: banners.image,
        banner: Ketherinn,
        tech: [
            { id: 1, tech: allTech.php, icon: allTechIcon.php },
            { id: 2, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
            { id: 3, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 5, tech: allTech.ajax, icon: allTechIcon.ajax },
            { id: 6, tech: allTech.html, icon: allTechIcon.html },
            { id: 7, tech: allTech.css, icon: allTechIcon.css },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/Todo-App.git",
    },
    {
        id: 16,
        projectName: "Marrage management",
        description: "This is a event management system. Here User can Book anything for the marrage ceremony. Also they people buy products for bride and groom like cloths, jewellery etc.",
        bannerType: banners.image,
        banner: marrage,
        tech: [
            { id: 1, tech: allTech.laravel, icon: allTechIcon.laravel },
            { id: 2, tech: allTech.css, icon: allTechIcon.css },
            { id: 3, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 5, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 6, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/Laravel-Marrage-Management-Project.git",

    },
    {
        id: 17,
        projectName: "Invenroty System",
        description: "This is a event Invenroty system. Here you can manage your sells category, manage your supplier, manage your sells, and generate your raport, and add delete and update project.",
        bannerType: banners.image,
        banner: inventory,
        tech: [
            { id: 1, tech: allTech.laravel, icon: allTechIcon.laravel },
            { id: 2, tech: allTech.javascript, icon: allTechIcon.javascript },
            { id: 3, tech: allTech.css, icon: allTechIcon.css },
            { id: 5, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 6, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
        ],
        livedemo: null,
        sourcecode: "https://github.com/Aunshon/Laravel-Inventory-Management-Syatem.git",

    },
    {
        id: 20,
        projectName: "Meal management desktop",
        description: "This is a desktop application made by java swing and sqlite database it helps to manage the expence and meal manage. It is especially made for bachelor. it task is to take input of expence, diposit, meal and it will meal rate and due of persons.",
        bannerType: banners.image,
        banner: meald,
        tech: [
            { id: 1, tech: allTech.java, icon: allTechIcon.java },
            { id: 2, tech: allTech.sqlite, icon: allTechIcon.sqlite },
            { id: 3, tech: allTech.netbeans, icon: allTechIcon.netbeans },
        ],
        livedemo: null,
        sourcecode: 'https://github.com/Aunshon/bachelor_food_meal_counting_system.git',

    },
    {
        id: 21,
        projectName: "Health care system",
        description: "This is a desktop application made by java swing and sqlite database. Here user can book appoinment. Doctor can accept or cancel appointment but to cancel apointment doctor must leave the message of the reason of cancelation. Also admin can monitor.",
        bannerType: banners.image,
        banner: healthCare,
        tech: [
            { id: 1, tech: allTech.java, icon: allTechIcon.java },
            { id: 2, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 3, tech: allTech.eclipse, icon: allTechIcon.eclipse },
        ],
        livedemo: null,
        sourcecode: 'https://github.com/Aunshon/Health_care_online_system.git',

    },
    {
        id: 22,
        projectName: "Departmental Store",
        description: "This is a desktop application made by java swing and sqlite database. Herer admin can add new products update and delete products. admin can also add to the cart and go to the billing page. Billing page will show details and total amount. and login is required at first.",
        bannerType: banners.image,
        banner: store,
        tech: [
            { id: 1, tech: allTech.java, icon: allTechIcon.java },
            { id: 2, tech: allTech.mysql, icon: allTechIcon.mysql },
            { id: 3, tech: allTech.netbeans, icon: allTechIcon.netbeans },
        ],
        livedemo: null,
        sourcecode: 'https://github.com/Aunshon/Departmental-store-management-ststem.git',

    },
];

//{
//    id: 2,
//    projectName: "Bobgobondhu",
//    description: "This project is about Bongobondhu Life, Struggle, History and all those information that as a bangali people everyone should know. And there are also some unique information him.",
//    bannerType: banners.image,
//    banner: bongobondhu,
//    tech: [
//        { id: 1, tech: allTech.laravel, icon: allTechIcon.laravel },
//        { id: 7, tech: allTech.javascript, icon: allTechIcon.javascript },
//        { id: 3, tech: allTech.mysql, icon: allTechIcon.mysql },
//        { id: 4, tech: allTech.html, icon: allTechIcon.html },
//        { id: 5, tech: allTech.css, icon: allTechIcon.css },
//        { id: 6, tech: allTech.bootstrap, icon: allTechIcon.bootstrap },
//        { id: 8, tech: allTech.jquery, icon: allTechIcon.jquery },
//    ],
//    livedemo: "https://sheikhmujiburrahman.com/",
//    sourcecode: null,
//
//},